import React, { useRef } from "react"
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage"

import GetStarted from "components/cta/GetStartedLight"
import Footer from "components/footers/FiveColumnDark"
import Menu from "components/Menu"
import MainContent from "components/MainContent"

const ServiceLandingPage = () => {
  const aboutRef = useRef(null)
  const productRef = useRef(null)
  const pricingRef = useRef(null)
  const contactRef = useRef(null)
  const testimonialRef= useRef(null)
  const refMap = React.useMemo(() => {
    return {
      aboutRef, productRef, pricingRef, contactRef, testimonialRef
    }
  }, [])

  const [currentPage, setCurrentPage] = React.useState('/')

  React.useEffect(() => {
    setCurrentPage(window.location.pathname)
  }, [])

  return (
    <AnimationRevealPage>
      <Menu currentPage={currentPage} refMap={refMap} />
      <MainContent currentPage={currentPage} refMap={refMap} />
      <GetStarted />
      <Footer ref={contactRef} />
    </AnimationRevealPage>
  )
}

export default ServiceLandingPage
