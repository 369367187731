import React, { forwardRef } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg"
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/clock.svg"
import { ReactComponent as FastIconImage } from "feather-icons/dist/icons/framer.svg"
import { ReactComponent as PerformanceIcon } from "feather-icons/dist/icons/zap.svg"
import QTradeDemoSrc from "images/qTrade-demo.png"

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 relative`
const TextColumn = styled(Column)(() => [ tw`md:w-8/12 mt-16 md:mt-0`, tw`md:ml-12 lg:ml-16 md:order-last` ])

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`

const FeatureHeadingContainer = tw.div`flex items-center`
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
  props.iconRoundedFull && tw`rounded-full`,
  props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
]}
  svg {
    ${tw`w-5 h-5`}
  }
`
const FeatureHeading = tw.div`ml-3 font-bold text-xl`

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`

const Element = ({
  subheading = "Our Product",
  heading,
  description = ``,
  imageSrc = QTradeDemoSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}, ref) => {
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Convenience",
      description: "Best convenience trading method for people.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: MoneyIcon,
      title: "Realtime",
      description: "Everythings realtimes for you.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    },
    {
      Icon: FastIconImage,
      title: "Speed",
      description: "You can quickly put an order with single click.",
      iconContainerCss: tw`bg-green-300 text-green-800`
    },
    {
      Icon: PerformanceIcon,
      title: "Performance",
      description: "Priority and Optimized speed for your order.",
      iconContainerCss: tw`bg-gray-300 text-gray-800`
    },
    {
      Icon: PerformanceIcon,
      title: "Smart with AI",
      description: "Applied AI for Stock Recommendation",
      iconContainerCss: tw`bg-primary-300 text-primary-800`
    }
  ]

  if (!features) features = defaultFeatures

  return (
    <TwoColumn ref={ref}>
      <ImageColumn>
        <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
      </ImageColumn>
      <TextColumn>
        <TextContent>
          <Subheading>{subheading}</Subheading>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <Features>
            {features.map((feature, index) => (
              <Feature key={index}>
                <FeatureHeadingContainer>
                  <FeatureIconContainer
                    iconFilled={iconFilled}
                    iconRoundedFull={iconRoundedFull}
                    css={feature.iconContainerCss || iconContainerCss}
                  >
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureHeading>{feature.title}</FeatureHeading>
                </FeatureHeadingContainer>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </Feature>
            ))}
          </Features>
        </TextContent>
      </TextColumn>
    </TwoColumn>
  )
}

export default forwardRef(Element)
