import React, { useCallback } from "react"
import tw from "twin.macro"

import HeaderBase, { NavLinks, NavLink , PrimaryLink } from "components/headers/light.js"
const Header = tw(HeaderBase)`max-w-none`
const Button = tw.span`font-bold p-3 cursor-pointer hover:(text-primary-500)`
const buttonRoundedCss = tw`rounded-full`

const Component = ({ currentPage = '/', refMap }) => {
  const scrollTo = useCallback((element) => {
    element.current.scrollIntoView({ behavior: 'smooth' })
  }, [])
  
  const navLinks = [
    <NavLinks key={1}>
      {currentPage === '/' && (
        <React.Fragment>
          <Button onClick={() => scrollTo(refMap.aboutRef)}>Who are we?</Button>
          <Button onClick={() => scrollTo(refMap.productRef)}>Products</Button>
          <Button onClick={() => scrollTo(refMap.testimonialRef)}>Testimonials</Button>
          <Button onClick={() => scrollTo(refMap.pricingRef)}>Pricing</Button>
          <Button onClick={() => scrollTo(refMap.contactRef)}>Contact Us</Button>
        </React.Fragment>
      )}
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink href="https://qts.quantech.vn/" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={buttonRoundedCss} 
        href="https://qts.quantech.vn/make-fun-together?r=4a7aba5b-3ac7-451f-b228-f3614f33638e">
        Sign Up
      </PrimaryLink>
    </NavLinks>
  ]

  return (
    <Header links={navLinks} />
  )
}

export default Component
