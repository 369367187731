import React, { useState } from "react"
import { Popper, Paper, makeStyles } from '@material-ui/core'
import { motion } from 'framer-motion'

import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"

import KennyPhamImg from "images/kenny-pham.jpeg"
import PhatNguyenImg from "images/phat-nguyen.jpg"
import LJWImg from "images/lee-jae-woo.jpg"
import KHCImg from "images/kang-hc.jpg"

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/2 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-48 h-48 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-2 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

const ProfileMap = {
  ['LEE JAE WOO']: (
    <React.Fragment>
      <b>SUMMARY</b>
      <p>- Extensive experience in ETF future option trading and scalping.</p>
      <p>- Highly skill at the management of risk to trading option and implement programming trading.</p>
      <p>- Proven the track record of achieving the profit for the company.</p>
      <p>- Obtained the trading strategy against initial future trading market.</p>
      <br />

      <b>EXPERIENCE</b><br/>
      <b>HI Securities Inc (Hyundai Heavy Industrial Group, Former name was CJ Securities Inc)</b><br />
      <b>Oct. 2007 – Nov 2011 Senior Manager, ETF future/option proprietary trading Team</b>
      <p>Built up ETF future/option proprietary trading team and training ETF future/option trading to team member</p>
      <p>Trading ETF future/option as scalping trade</p>
      <p>- Obtain the sustainable profit in Korea ETF future/option market</p>
      <p>- Trading volume of a day hit US$500million to US$1.5billion</p>
      <p>- Trading and managing team with the experience to earn 20 million US Dollar for 7 year.</p>
      <p>- Build up the logic system trading to lessen the risk exposure.</p>
      <p>- Utilized one-minute charts taking the profits in ETF Future/ option market</p>
      <p>- Researching China ETF future/option market</p>
      <br/>

      <b>TongYang Securities Inc (Yuanta Securitues for now from Taiwan)</b><br />
      <b>April. 2002 – March. 2007    Manager, Finance Technology Team</b>
      <p>Build-up the growing market strategy against ETF future / option trading</p>
      <p>Create logic system trading method</p>
      <p>Co-work with scalping team, derivatives team, and system trading team</p>
      <p>- Nominated the most profitable trader (Within ranking 3) in Korea</p>
      <p>- Track record from April. 2002 to 2007</p>

      <b>2012-2015 Samick Trading CEO </b>
      <p>- CSI300 Index Future Trading in Shanghai Future Trading Building</p>
      <p>- Profit : US$800,000 US$200,000(Capital)</p><br />

      <b>2015-2018 </b><br />
      <p>- Samick Furniture Co. Ltd. Korea Director Marketing, Finance, Design</p><br />

      <b>2019-Now</b><br />
      <p>- Samick Design VN, Investor</p>
      <p>- Dynasty Technology, Investor, Trader</p>
      <p>- Quantech, Investor, Trader</p>
    </React.Fragment>
  )
  ,
  ['KENNY PHAM']: (
    <React.Fragment>
      <b>SUMMARY</b>
      <p>- Bachelor of Financial Cooperation in FPT University.</p>
      <p>- CFA Level 1.</p>
      <p>- Good at analysis Financial report and evaluate corporation.</p>
      <p>- 7 years experience in Vietnam Stock market (2014-2021) </p>
      <p>- 3 securities company (VCBS, VPS, Mirae Asset).</p>
      <p>- Managed fund 1M US.DOLLAR from <b>2019-Now</b> with monthly profitable 3-4%.</p>
    </React.Fragment>
  ),
  ['CHOI KWANGHUYN']: (
    <React.Fragment>
      <b>SUMMARY</b>
      <p>- High experience and knowledge in Derivatives Market researching.</p>
      <p>- Extensive experience in Index future, option, elw, etc. trading.</p>
      <br />

      <b>EXPERIENCE</b><br/>
      <b>TongYang Securities Inc (Jan. 2007 – Jun. 2011) </b>
      <p>- Researching as derevatives market analyst (Research Center)</p>
      <p>- Trading Index future/option as scalping trade (Finance Engineering Team)</p>
      <br />

      <b>Hi Securities Inc (Jul. 2011 – Feb. 2013) </b>
      <p>- Trading Index future/option as scalping trade(Finance Technology Team)</p>
    </React.Fragment>
  ),
  ['PHAT NGUYEN']: (
    <React.Fragment>
      <b>SUMMARY</b>
      <p>- I graduated from FPT University (Ho Chi Minh Campus) with GPA is 8.4/10.</p>
      <p>- Archive IBM Training and Application Development on BLUEMIX Certificate in March 2016.</p>
      <p>- Archive IBM Award for winning the best application on BLUEMIX and received $24,000 for Start-up in March 2016.</p>
      <br/><br/>
      <i>More detail:</i> <b><a href="https://phatnt.com" target="_blank" rel="noreferrer">https://phatnt.com</a></b>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(5),
      maxWidth: 800
    }
  }
})

export default ({
  cards = [
    {
      imageSrc: LJWImg,
      position: "CEO, Founder & Trader",
      name: "LEE JAE WOO",
      links: [],
    },
    {
      imageSrc: KennyPhamImg,
      position: "CFO & Trader",
      name: "KENNY PHAM",
      links: [],
    },
    {
      imageSrc: KHCImg,
      position: "Trader",
      name: "CHOI KWANGHUYN",
      links: [],
    },
    {
      imageSrc: PhatNguyenImg,
      position: "Chief Engineer",
      name: "PHAT NGUYEN",
      links: [],
    }
  ]
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [person, setPerson] = useState(null)

  return (
    <React.Fragment>
      <Cards>
        {cards.map((card, index) => (
          <Card key={index}
            onMouseEnter={(e) => {
              setAnchorEl(e.target)
              setPerson(card.name)
            }}
            onClick={(e) => {
              if (!anchorEl) {
                setAnchorEl(e.target)
                setPerson(card.name)
              } else {
                setAnchorEl(null)
                setPerson(null)
              }
            }}
          >
            <CardImage imageSrc={card.imageSrc} />
            <CardContent>
              <span className="position">{card.position}</span>
              <span className="name">{card.name}</span>
              <CardLinks>
                {card.links.map((link, linkIndex) => (
                  <a key={linkIndex} className="link" href={link.url}>
                    <link.icon className="icon" />
                  </a>
                ))}
              </CardLinks>
            </CardContent>
          </Card>
        ))}
      </Cards>

      <Popper
        onClick={() => {
          setAnchorEl(null)
          setPerson(null)
        }}
        open={anchorEl !== null} anchorEl={anchorEl} placement="left">
        <motion.div
          animate={{
            opacity: [0, 0.5, 1],
            y: [20, 10, 0]
          }}
          transition={{ duration: 0.2 }}
        >
          <Paper className={classes.paper}>
            {ProfileMap[person]}
          </Paper>
        </motion.div>
      </Popper>
    </React.Fragment>
  )
}
