import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js"
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg"
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg"
import MiraeAssetLogo from "images/mirae-asset-logo.svg"
import SamickLogo from "images/samick-logo.jpeg"
import IBKBankLogo from "images/ibkbank-logo.jpg"
import DongjinLogo from "images/dongjin-logo.png"
import VinaSecuritiesLogo from "images/vinasecurities-logo.jpeg"

const Subheading = tw(SubheadingBase)`text-center`
const Testimonials = tw.div`flex flex-col md:flex-row lg:flex-row items-center`
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`
const Image = tw.img`w-64`

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`

export default ({
  subheading = "We got big supports from",
  heading = "Our partners",
  testimonials = [
    {
      imageSrc: MiraeAssetLogo,
      link: 'https://masvn.com'
    },
    {
      imageSrc: SamickLogo,
      link: 'https://samickmall.vn'
    },
    {
      imageSrc: IBKBankLogo,
      link: '#'
    },
    {
      imageSrc: DongjinLogo,
      link: 'http://www.dongjinvietnam.com'
    },
    {
      imageSrc: VinaSecuritiesLogo,
      link: 'https://vinasecurities.com'
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <a href={testimonial.link} target="_blank" rel="noreferrer">
                <Image src={testimonial.imageSrc} />
              </a>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  )
}
